import { createRouter,createWebHistory,createWebHashHistory } from 'vue-router'
import LoginPassword from '../views/login/LoginPassword.vue'
import LoginPhoneNumber from '../views/login/LoginPhoneNumber.vue'
import Admin from '../views/admin/Main.vue'
import Protocol from '../views/admin/Protocol.vue'
import Order from '../views/admin/Order.vue'
import Payback from '../views/admin/Payback.vue'
import Rebate from '../views/admin/Rebate.vue'
import Settings from '../views/admin/setting/Settings.vue'
import UserManagement from '../views/admin/setting/UserManagement.vue'
import MerchantManagement from '../views/admin/setting/MerchantManagement.vue'
import Cookies from 'js-cookie'


const routes = [
    { path: '/login_password', component: LoginPassword },
    { path: '/login_phone', component: LoginPhoneNumber },
    { 
        path: '/admin', 
        component: Admin,
        children: [
            {
                path: 'protocol',
                component: Protocol
            },
            {
                path: 'order',
                component: Order
            },
            {
                path: 'payback',
                component: Payback
            },
            {
                path: 'rebate',
                component: Rebate
            } 
        ]
    },
    {
        path: '/settings',
        component: Settings,
        children: [
            {
                path: 'user',
                component: UserManagement,
            },
            {
                path: 'merchant',
                component: MerchantManagement,
            },
        ]
    }
    
]

const router = createRouter({
    // history: createWebHistory(),
    history:createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next)=> {
    if(to.path == '/login_password' || to.path == '/login_phone'){
        next()
    }else{
        const token = Cookies.get('token');
        if(token == null || token == ''){
            next('/login_password')
        }else{
            next()
        }
    }
}) 

export default router