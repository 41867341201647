import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 获取客户回款列表
export function get_payback_list(page, pageSize, year) {

    return request({
        url: '/receipts',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize,
            year: year
        }
    })
}

// 导出回款
export function export_payback(startTime, endTime) {

    return request({
        url: '/receipts/export',
        headers: {
            'Authorization': token
        },
        method: 'get',
        responseType: "blob",
        params: {
            startTime,
            endTime
        }
    })
}

// 下载回款模板
export function download_template() {

    return request({
        url: '/receipts/excelModel',
        headers: {
            'Authorization': token
        },
        method: 'get',
        responseType: "blob"
    })
}
