<template>
    <div id="order">
        <div class="order-header">
            <el-upload
                class="upload-demo"
                :headers="headerObj"
                action="https://wechatapp-api.hhsm.cc/receipt/import_and_dealt"
                method="post"
                accept=".xls, .xlsx"
                :on-success="successUpload"
                :on-error="errorUpload"
            >
                <el-button type="primary" class="add-protocol">
                        <img class="add-icon" src="../../assets/img/add.png">
                        <span class="text1">上传回款</span>
                    </el-button>
            </el-upload>
            <el-button @click="downloadTemplate" type="primary" class="add-protocol">
                    <img class="add-icon" src="../../assets/img/download.png">
                    <span class="text1">下载模板</span>
            </el-button>
            <div>
                <el-button @click="exportVisible=true" type="primary" class="add-protocol">
                    <img class="add-icon" src="../../assets/img/export.png">
                    <span class="text1">导出回款</span>
                </el-button>
            </div>        
            <el-date-picker v-model="year" type="year" :clearable="false" @change="changeYear" style="width: 7.5vw;"/>
            
        </div>

        <el-dialog @close="closeExport" v-model="exportVisible" width="25%" title="导出回款">
            <div class="export-content">
                <div class="export-label">请选择导出日期范围：</div>
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 18vw;"
                />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeExport">取消</el-button>
                    <el-button type="primary" @click="exportPayback">确认</el-button>
                </span>
            </template>
        </el-dialog> 

        <div class="main">
            <el-table :data="paybackList" class="table-head" row-class-name="table-row" max-height="530px">
                <el-table-column prop="number" label="序号" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.ID }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderDate" label="单据日期" align="center" label-class-name="column" width="130px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.BillDate.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="billNumber" label="单据编号" align="center" label-class-name="column" width="180px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.BillNumber }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center" label-class-name="column" width="130px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CreatedAt.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="customer" label="客户" align="center" label-class-name="column" width="200px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CustomerName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="department" label="部门" align="center" label-class-name="column" width="110px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Department }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="salesPerson" label="业务员" align="center" label-class-name="column" width="110px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Salesperson }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="settlementMethod" label="商品名称" align="center" label-class-name="column" width="250px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.SettlementMethod }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="账号" align="center" label-class-name="column" width="200px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Account }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="invoiceNumber" label="数量" align="center" label-class-name="column" width="110px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.InvoiceNumber }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountReceived" label="收款金额" align="center" label-class-name="column" width="110px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.AmountReceived/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountReceived" label="商户编码" align="center" label-class-name="column" width="120px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CustomerCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountReceived" label="商品属性" align="center" label-class-name="column" width="110px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.GoodsAttribute }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountReceived" label="现金结算金额" align="center" label-class-name="column" width="140px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CashAmount/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountReceived" label="返利抵扣金额" align="center" label-class-name="column" width="140px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.RebateAmount/100 }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                v-model:current-page="currentPage"                                
                layout="total, prev, pager, next, jumper"
                :total="total"            
                @current-change="switchPages"         
                />
            </div>
        </div>
    </div>
</template>

<script>
import {get_payback_list, export_payback, download_template} from "@/network/payback"
import { onBeforeMount, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'

export default{
    setup(){
        let currentPage = ref(1)
        let paybackList = ref([])
        let total = ref(0);
        let pageSize = ref(10)
        let totalAmount = ref(1000)
        let year = ref('2023')
        let exportVisible = ref(false)
        let dateRange = ref()
        
        const headerObj = reactive({ 'Authorization': Cookies.get('token') })

        onBeforeMount(() =>{
            getPaybackList()
        })

        const getPaybackList = () =>{
            get_payback_list(currentPage.value, pageSize.value, year.value)
            .then((res =>{
                total.value = res.data.data.receiptCount
                paybackList.value = res.data.data.receipts
            }))
            .catch((err) =>{
                console.log(err)
            })
        }

        const changeYear = () =>{
            year.value = year.value.getFullYear().toString()
            paybackList.value = []
            getPaybackList()
        }

        const switchPages = (e) =>{
            currentPage.value = e
            getPaybackList()
        }

        const successUpload = () =>{
            ElMessage({
                message: '回款上传成功',
                type: 'success',
            })
            getPaybackList()
        }

        const errorUpload = (err) =>{
            let msg =JSON.parse(err.message).message.replace('invalid request: ', '')
            ElMessage({
                showClose: true,
                message: msg,
                type: 'error',
                duration: 0
            })
        }

        // 导出回款
        const exportPayback = () =>{
            let startDate = dateRange.value[0]
            let endDate = dateRange.value[1]
            export_payback(startDate, endDate)
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '回款列表.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '回款列表导出成功',
                    type: 'success',
                })
                closeExport()
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 关闭导出弹窗
        const closeExport = () =>{
            dateRange.value = ''
            exportVisible.value = false
        }

        // 下载回款模板
        const downloadTemplate = () =>{
            download_template()
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '回款模板.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '回款模板下载成功',
                    type: 'success',
                })
            })
            .catch(err =>{
                console.log(err)
            })
        }

        return {
            currentPage,paybackList,total,pageSize,totalAmount,
            headerObj,year,exportVisible,dateRange,
            getPaybackList,switchPages,successUpload,errorUpload,changeYear,
            exportPayback,closeExport,downloadTemplate
        }

    }
}
</script>

<style>

</style>