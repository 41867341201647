<template>
    <div id="rebate">
        <div class="order-header">
            <el-button @click="exportAll" type="primary" class="add-protocol">
                <img class="add-icon" src="../../assets/img/export.png">
                <span class="text1">导出回款返利</span>
            </el-button>
            <div>
                <el-button @click="exportVisible=true" type="primary" class="add-protocol">
                    <img class="add-icon" src="../../assets/img/export.png">
                    <span class="text1">导出返利</span>
                </el-button>
            </div>
            
            <el-date-picker v-model="year" type="year" :clearable="false" @change="changeYear" style="width: 7.5vw;"/>
            
        </div>

        <el-dialog @close="closeExport" v-model="exportVisible" width="25%" title="导出返利">
            <div class="export-content">
                <div class="export-label">请选择导出日期范围：</div>
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 18vw;"
                />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeExport">取消</el-button>
                    <el-button type="primary" @click="exportRebate">确认</el-button>
                </span>
            </template>
        </el-dialog> 

        <div class="content">
            <el-table :data="rebateList" class="table-head" row-class-name="table-row">
                <el-table-column prop="number" label="序号" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.ID }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="customerName" label="客户编码" align="center" label-class-name="column" width="120px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CustomerCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="payback" label="收款ID" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.ReceiptID }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="winesOrder" label="协议ID" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.AgreementID }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="rebateSend" label="返利金额" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">￥{{ scope.row.Amount/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="rebateNotUsed" label="创建时间" align="center" label-class-name="column" width="140px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CreatedAt.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountSend" label="返利前用户余额" align="center" label-class-name="column" width="150px">
                    <template #default="scope">
                        <span class="row">￥{{ scope.row.BeforeBalance/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountUsed" label="返利后用户余额" align="center" label-class-name="column" width="150px">
                    <template #default="scope">
                        <span class="row">￥{{ scope.row.AfterBalance/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountUsed" label="返利前用户返利金额" align="center" label-class-name="column" width="190px">
                    <template #default="scope">
                        <span class="row">￥{{ scope.row.BeforeRebateAmount/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountUsed" label="返利后用户返利金额" align="center" label-class-name="column" width="190px">
                    <template #default="scope">
                        <span class="row">￥{{ scope.row.AfterRebateAmount/100 }}</span>
                    </template>
                </el-table-column>
                
            </el-table>
            <div class="pagination">
                <el-pagination
                v-model:current-page="currentPage"                                
                layout="total, prev, pager, next, jumper"
                :total="total"            
                @current-change="switchPages"         
                />
            </div>
        </div>
        
    </div>
</template>

<script>
import {get_rebate_list, export_all, export_rebate} from "@/network/rebate"
import { onBeforeMount, ref } from 'vue'
import { ElMessage } from 'element-plus'

export default {
    setup(){
        let currentPage = ref(1)
        let rebateList = ref([])
        let total = ref(0);
        let pageSize = ref(10)
        let year = ref('2023')
        let exportVisible = ref(false)  // 导出弹窗是否可见
        let dateRange = ref()  // 导出返利时间范围

        onBeforeMount(() =>{
            getRebateList()
        })

        const getRebateList = () =>{
            get_rebate_list(currentPage.value, pageSize.value, Number(year.value))
            .then((res =>{
                total.value = res.data.data.count
                rebateList.value = res.data.data.rebates
            }))
            .catch((err) =>{
                console.log(err)
            })
        }

        // 导出返利回款与余额
        const exportAll = () =>{
            export_all(year.value)
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '返利回款余额汇总表.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '汇总表导出成功',
                    type: 'success',
                })
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 选择时间段导出返利
        const exportRebate = () =>{
            let startDate = dateRange.value[0]
            let endDate = dateRange.value[1]
            export_rebate(startDate, endDate)
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '返利列表.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '返利列表导出成功',
                    type: 'success',
                })
                closeExport()
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 关闭导出弹窗
        const closeExport = () =>{
            dateRange.value = ''
            exportVisible.value = false
        }

        const switchPages = (e) =>{
            currentPage.value = e
            getRebateList()
        }

        const changeYear = () =>{
            year.value = year.value.getFullYear().toString()
            rebateList.value = []
            getRebateList()
        }

        return {
            currentPage,rebateList,total,pageSize,year,exportVisible,dateRange,
            getRebateList,switchPages,changeYear,exportAll,exportRebate,closeExport
        }

    },
    
}
</script>

<style>
.el-tabs__nav{
    margin-left: 2.92vw;
}

.el-tabs__nav-wrap::after{
    /* border: none; */
    height: 0;
}

.el-tabs__item{
    font-size: 1.042vw;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 2.68vh;
}


.content{
    position: absolute;
    top: 10.648vh;
    width: 86.51vw;
    height: 86.574vh;
    background-color: #FFFFFF;
    border-radius: 30px;
    display: flex;
    justify-content: center;
}
</style>