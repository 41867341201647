import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 获取客户订单列表
export function get_order_list(page, pageSize, year) {

    return request({
        url: '/sales_invoice',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize,
            year: year
        },
    })
}

// 上传订单
export function upload_order() {

    return request({
        url: '/sales_invoice/import',
        headers: {
            
        },
        method: 'post',
    })
}

// 导出订单
export function export_order(startTime, endTime) {

    return request({
        url: '/sales_invoice/export',
        headers: {
            'Authorization': token
        },
        method: 'get',
        responseType: "blob",
        params: {
            startTime,
            endTime
        }
    })
}

// 下载订单模板
export function download_template() {

    return request({
        url: '/sales_invoice/excelModel',
        headers: {
            'Authorization': token
        },
        method: 'get',
        responseType: "blob"
    })
}
