import axios from 'axios'
import Cookies from 'js-cookie'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'http://1.13.169.169:30348',
  baseURL: 'https://wechatapp-api.hhsm.cc',
  // 超时
  timeout: 60000
})

service.interceptors.request.use(
  config => {
    const token = Cookies.get("token") ? Cookies.get("token") : "";
    if (token) {// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        config.headers.Authorization = `Bearer ${ token }`;
    }
    return config;
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// service.interceptors.response.use(
//   response => {
//     return response.data
//   },
//   error => {
//     return Promise.reject(error)
//   }

// )


export default service