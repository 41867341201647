import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 获取返利发送客户列表
export function get_rebate_list(page, pageSize, year) {

    return request({
        url: '/rebate',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize,
            year: year
        }
    })
}

// 导出回款返利与余额
export function export_all(year) {

    return request({
        url: '/user/exportReceiptAndRebateByCustomerCode',
        headers: {
            'Authorization': token
        },
        method: 'get',
        responseType: "blob",
        params: {
            year
        }
    })
}

// 选择时间段导出返利
export function export_rebate(startTime, endTime) {

    return request({
        url: '/rebate/export',
        headers: {
            'Authorization': token
        },
        method: 'get',
        responseType: "blob",
        params: {
            startTime,
            endTime
        }
    })
}