import { createStore } from 'vuex'
import Vuex from 'vuex'
import login_with_password from '../network/login'


const store = new Vuex.Store({
    state:{
        token: null
    },
    // 监听数据变化
    getters:{    
		getToken(state){   
            return state.token
	    }
	},
    mutations:{
		SetToken(state, value) {
	        state.token = value;
	    }
	},


  actions: {

    }

})

export default store