import request from '@/utils/request'

// 密码登录方法
export function login_with_password(employeeId, password) {
    const data = {
        employeeId,
        password
    }
    return request({
        url: '/manager/login',
        method: 'post',
        data: data
    })
}

// 验证码登录
export function login_with_verify_code(phone, code) {
    const data = {
        code: code,
        phone: phone
    }
    return request({
        url: '/manager/loginBySmsCode',
        method: 'post',
        data: data,
    })
}

// 请求验证码
export function request_verify_code(phone) {
    return request({
        url: `/manager/sendSmsCode`,
        method: 'post',
        params: {
            phone: phone
        }
    })
}
  
  
  