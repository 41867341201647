<template>
    <div>
        <el-popover placement="bottom" width="19.0625vw" trigger="click" popper-class="info-poper">
            <template #reference>
                <div class="avatar">
                    <div class="first-name">{{ person.firstName }}</div>
                </div>
            </template>
            <div>
                <div class="info">
                    <div class="info-row">
                        <img class="info-icon" src="../assets/img/name.png">
                        <span class="info-text">姓名</span>
                        <span class="info-content">{{ person.Username }}</span>
                    </div>
                    <div class="info-row">
                        <img class="info-icon" src="../assets/img/phone.png">
                        <span class="info-text">电话</span>
                        <span class="info-content">{{ person.Phone }}</span>
                    </div>
                    <div class="info-row">
                        <img class="info-icon" src="../assets/img/id.png">
                        <span class="info-text">员工ID</span>
                        <span class="info-content">{{ person.EmployeeID }}</span>
                    </div>
                </div>
                <div class="info-operate">
                    <div class="operate1" @click="updateDialogVisible = true">
                        <img class="info-icon" src="../assets/img/update.png">
                        <span class="update-password">修改密码</span>
                    </div>
                    <div class="operate2" @click="logout">
                        <img class="info-icon" src="../assets/img/logout.png">
                        <span class="logout">退出登录</span>
                    </div>
                </div>
                
            </div>               
        </el-popover>
        <el-dialog v-model="updateDialogVisible" width="21vw" :before-close="handleClose">
            <template #header="titleClass">
                <div :class="titleClass">修改密码</div>
            </template>
            <div class="bind-phone">您已绑定：{{ person.Phone }}</div>
            <el-form label-width="100px" :model="updateForm" style="max-width: 460px">
                <div class="get-checkcoed">
                    <div class="box-phone">
                        <img class="icon-phone" src="../assets/img/phone.png">
                        <el-input class="input-checkcode" v-model="updateForm.checkcode" placeholder="请输入手机验证码"/>
                    </div>
                    
                    <el-button @click="sendSmsCode" v-if="countNum==60 || countNum==-1" class="button-getCheckcode">获取验证码</el-button>
                    <el-button v-else class="button-getCheckcode">{{ countNum }}s</el-button>
                </div>  
                <el-input class="input-password" type="password" v-model="updateForm.newPassword" placeholder="请输入新密码"/>
                <el-input class="input-password" type="password" v-model="updateForm.checkNewPassword" placeholder="请确认新密码"/>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="button-cancel" @click="updateDialogVisible = false">取消</el-button>
                    <el-button class="button-confirm" type="primary" @click="updatePassword">确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>

</template>

<script>
import {get_profile, send_sms_code, update_password} from "@/network/getInfo"
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'

export default {
    data(){
        return{
            person: {
                Username: '',
                Phone: '',
                EmployeeID: '',
                firstName: ''
            },
            updateDialogVisible: false,
            updateForm: {
                checkcode: '',
                newPassword: '',
                checkNewPassword: ''
            },
            countNum: 60  // 倒计时初值
            
        }
    },
    methods: {
        logout(){
            Cookies.remove("token")
            this.$router.push("/login_password")
        },

        // 修改密码时发送短信验证码
        sendSmsCode(){
            send_sms_code(this.person.Phone)
            .then(res =>{
                ElMessage({
                    message: '验证码已发送',
                    type: 'success',
                })
                this.countdown()
            })
            .catch(err =>{
                console.log(err)
            })
        },

        // 验证码倒计时
        countdown(){
            let count = this.countNum
            let timer = setInterval(() =>{
                count -= 1
                this.countNum = count
                if(count<0){
                    clearInterval(timer)
                    this.countNum = 60
                }
            }, 1000)
        },

        // 根据短信验证码修改密码
        updatePassword(){
            if(this.updateForm.checkcode == ''){
                ElMessage({
                    message: '验证码为空',
                    type: 'warning',
                })
            }else if(this.updateForm.newPassword == '' || this.updateForm.checkNewPassword == ''){
                ElMessage({
                    message: '新密码为空',
                    type: 'warning',
                })
            }else if(this.updateForm.newPassword != this.updateForm.checkNewPassword){
                ElMessage({
                    message: '新密码不一致',
                    type: 'warning',
                })
            }else{
                update_password(this.updateForm.checkcode, this.updateForm.newPassword, this.person.Phone)
                .then(res =>{
                    console.log(res)

                    // 关闭弹窗
                    this.updateDialogVisible = false
                    // 提示密码修改成功
                    ElMessage({
                        message: '密码修改成功',
                        type: 'success',
                    })
                    // 退出登录
                    this.logout()
                })
                .catch(err =>{
                    console.log(err)
                    ElMessage.error('验证码错误')
                })
            }
        }

    },
    created(){
        get_profile().then((res =>{
            let profile = res.data.data
            this.person.Username = profile.Username
            this.person.Phone = profile.Phone
            this.person.EmployeeID = profile.EmployeeID
            this.person.firstName = profile.Username.charAt(0)
        }),(err =>{
            console.log(err)
        }))
    },

}
</script>

<style>
.avatar{
    position: absolute;
    top: 4.26vh;
    right: 3.02vw;
    width: 4.8vh;
    height: 4.8vh;
    background: #63A4CB;;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-name{
    /* margin: auto; */
    font-size: 20px;
    color: white;
    
}

.info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 3.2vh;
    padding-left: 0.4vw;
}

.info-row{
    display: flex;
    margin-bottom: 1.8vh;
}

.info-icon{
    width: 1.042vw;
    height: 1.042vw;
}

.info-text{
    color: #383838;
    font-size: 0.9375vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.413vh;
    margin-left: 0.417vw;
    width: 3.8vw;
}

.info-content{
    color: #383838;
    font-size: 0.9375vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.413vh;
}

.info-operate{
    display: flex;
    align-items: center;
    margin-top: 3.028vh;
}

.update-password{
    font-size: 0.9375vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.413vh;
    margin-left: 0.417vw;
    color: #409dff;
}

.logout{
    font-size: 0.9375vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.413vh;
    margin-left: 0.417vw;
    color: #ff5d3b;
}

.operate1{
    display: flex;
}

.operate2{
    display: flex;
    margin-left: 6.094vw;
}

.titleClass{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(56, 56, 56, 1);
    text-align: left;
}

.el-dialog__body{
    padding-top: 0;
    padding-bottom: 0;
}

.bind-phone{
    margin-bottom: 1.3vh;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 20.27px;
    color: rgba(56, 56, 56, 1);
}

.get-checkcoed{
    display: flex;
    align-items: center;
    margin-bottom: 1.3vh;
}

.box-phone{
    border: 1px solid #E5E5E5;;
    /* width: 2.135vw; */
    /* height: 2.778vh; */
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.icon-phone{
    width: 1.042vw;
    height: 1.042vw;
    margin-left: 0.61vw;
    margin-right: 0.61vw;
}

.input-checkcode{
    width: 10.677vw;
}

.button-getCheckcode{
    width: 4.48vw;
    /* height: 3.33vh; */
    border: 1px solid #2A82E4;
    border-radius: 4px;
    margin-left: 0.9vw;
    font-size: 0.73vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 3.148vh;
    color: rgba(42, 130, 228, 1);
}

.input-password{
    margin-bottom: 1.3vh;
}

.button-cancel{
    border: 1px solid #2A82E4;
    color: rgba(42, 130, 228, 1);
    font-weight: 400;
    letter-spacing: 0px;
}

.button-confirm{
    font-weight: 400;
    letter-spacing: 0px;
}

</style>