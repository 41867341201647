<template>
    <div id="main" class="common-layout">
        <el-container>
            <el-aside class="aside">
                <el-menu
                    default-active="/admin/protocol"
                    router>
                    <el-menu-item class="menu" :index="`/admin/protocol`">客户协议</el-menu-item>
                    <el-menu-item class="menu" :index="`/admin/order`">客户订单</el-menu-item>
                    <el-menu-item class="menu" :index="`/admin/payback`">客户回款</el-menu-item>
                    <el-menu-item class="menu" :index="`/admin/rebate`">返利发送</el-menu-item>
                </el-menu>
                
                <img src="../../assets/img/setting.png" class="setting" @click="toSettings">
            </el-aside>
            <el-main>
                <userInfo></userInfo>
                <router-view></router-view>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import UserInfo from '@/components/UserInfo.vue'

export default{

    components: { 
        'userInfo': UserInfo,  
    },
    data(){
        return{
            
            
        }
    },
    methods: {
        toSettings(){
            this.$router.push("/settings/user")
        }


    },

}
</script>

<style>
#main{
    width: 100vw;
    height: 100vh;
    background: rgba(248, 250, 251, 1);
}

.aside{
    padding-top: 13.61vh;
    width: 10.78vw;
}

.el-menu{
    border: none;
    background-color: rgba(248, 250, 251, 1);
}

.menu{
    font-size: 1.04vw;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 2.68vh;
    justify-content: center;
}

.el-menu-item{
    height: 5.185vh;
    margin-bottom: 15px;
}

.el-menu-item.is-active{
    background: #2A82E4;
    border-radius: 1.5625vw;
    width: 7.656vw;
    height: 3.6vh;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

.setting{
    position: absolute;
    left: 1.927vw;
    bottom: 3.89vh;
}
</style>
