import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 创建协议草稿
export function create_protocol_draft(draftList) {

    return request({
        url: '/agreementDraft/create_in_batch',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: draftList
    })
}

// 获取协议草稿列表
export function get_draft_list(page, pageSize, year, offset) {

    return request({
        url: '/agreementDraft/distinct_title',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize,
            year: year,
            offset: offset
        }
    })
}

// 根据名称获取协议草稿
export function get_draft_by_title(title) {

    return request({
        url: '/agreementDraft/title',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            title
        }
    })
}

// 删除协议草稿
export function delete_protocol_draft(title) {

    return request({
        url: '/agreementDraft/title',
        headers: {
            'Authorization': token
        },
        method: 'delete',
        params: {
            title
        }
    })
}

// 检查协议草稿名称是否重复
export function check_draft_title(title) {

    return request({
        url: '/agreementDraft/check_title',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            title
        }
    })
}

// 编辑草稿
export function edit_draft(draftList) {

    return request({
        url: '/agreementDraft/update',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: draftList
    })
}