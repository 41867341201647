import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 获取管理员列表
export function get_administrator_list(page, pageSize) {

    return request({
        url: '/manager/list',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize
        },
    })
}

// 删除管理员
export function remove_administrator(employeeId) {

    return request({
        url: '/manager/' + employeeId,
        headers: {
            'Authorization': token
        },
        method: 'delete',
    })
}

// 添加普通管理员
export function add_administrator(administrator) {

    return request({
        url: '/manager/add',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: administrator
    })
}

// 添加超级管理员
export function add_super_administrator(administrator) {

    return request({
        url: '/manager/add/super',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: administrator
    })
}