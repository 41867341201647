<template>
    <div id="loginPassword" class="box1">
        <div class="box2">
            <div class="left">
                <img class="img1" src="https://img.js.design/assets/img/648be2fb8a865ac9711dde06.png#be1e0d4237986e50fbec66ae23288c2d">
                <img class="img2" src="https://img.js.design/assets/img/648be2f59c77113c861286ba.png#c7bd041233684a0c7386f41e87d06bc9">
                <div class="company">阜阳市华海商贸有限公司</div>
            </div>
            <div class="right">
                <form>
                    <div class="phone-login">密码登录</div>
                    <div class="input-box">
                        <input class="input1" v-model="employeeId" placeholder="请输入工号" />
                        <div class="line"></div>
                        <input class="input2" v-model="password" type="password" placeholder="请输入密码" />
                        <div class="line"></div>
                    </div>
                    <div class="forget">
                        <img class="icon-people" src="../../assets/img/people.png">
                        <el-link type="primary" href="" class="forget-password">忘记密码</el-link>
                    </div>
                    
                    <el-button type="primary" class="button" @click="handleLogin">
                        <div class="button-text">立即登录</div>
                    </el-button>
                </form>
                
                <div class="login-switch">
                    <img class="icon-arrow" src="../../assets/img/arrow-right.png">
                    <el-link type="primary" href="#/login_phone" class="link-switch">验证码登录</el-link>
                </div>
                
            </div>
        </div>
        
    </div>
    
</template>

<script>
import {login_with_password} from "@/network/login"
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'

export default {
    data(){
        return {
            employeeId: '',
            password: ''
        }
    },
    methods:{
        handleLogin(){
            if(this.employeeId=='' || this.password==''){
                ElMessage({
                    message: '工号或密码不能为空',
                    type: 'warning',
                })
            }else{
                login_with_password(this.employeeId, this.password).then((res =>{
                    let token = res.data.data.accessToken
                    Cookies.set('token', token); 
                    this.$router.push('/admin/protocol')
                }),err =>{
                    console.log(err)
                    ElMessage.error('工号或密码错误')
                })
            }                         
        },

    }
}
</script>

<style>
    .box1{
        width: 100vw;
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        background: linear-gradient(90deg, rgba(197, 220, 240, 0.9) 0%, rgba(135, 180, 232, 0.9) 99.72%);
    }

    .box2{
        width: 86.56vw;
        height: 85.28vh;
        position: absolute;
        left: 4.84vw;
        top: 6.48vh;
        border-radius: 30px;
        background: rgba(255, 255, 255, 0.3);
        display: flex;
    }
    .left{
        width: 50%;
    }

    .right{
        width: 50%;
        border-radius: 30px;
        background: rgba(255, 255, 255, 1);
    }

    .img1{
        position: absolute;
        width: 33.85vw;
        height: 26.76vh;
        left: 4.635vw;
        top: 20.185vh;
    }

    .img2{
        position: absolute;
        left: 6.25vw;
        top: 46.94vh;
        width: 32.24vw;
        height: 9.35vh;
    }

    .company{
        position: absolute;
        left: 19.427vw;
        bottom: 4.074vh;
        height: 4.9074vh;
        font-size: 1.875vw;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 4.827vh;
        color: rgba(209, 38, 30, 1);
        text-align: left;
        vertical-align: top;
    }

    .phone-login{
        margin-left: 3.125vw;
        margin-top: 13.52vh;
        font-size: 3.125vw;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 8.044vh;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
    }

    .button{
        position: absolute;
        left: 46.406vw;
        bottom: 8.796vh;
        width: 13.22vw;
        height: 8.148vh;
        border-radius: 4px;
        background: rgba(42, 130, 228, 1);
    }

    .button-text{
        width: 6.25vw;
        height: 4.074vh;
        font-size: 1.5625vw;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 4.02vh;
        color: rgba(255, 255, 255, 1);
    }

    .input-box{
        margin-left: 3.02vw;
        margin-right: 8.33vw;
        margin-top: 6.48vh;
    }

    .input1{
        width: 31.25vw;
        border: none;
        font-size: 1.25vw;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 1.296vh;
        color: rgba(144, 147, 153, 1);
        margin-left: 1.25vw;
        margin-bottom: 1.8vh;
        outline: none;
    }

    .input2{
        width: 31.25vw;
        border: none;
        font-size: 1.25vw;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 1.296vh;
        color: rgba(144, 147, 153, 1);
        margin-left: 1.25vw;
        margin-bottom: 1.8vh;
        margin-top: 8.89vh;
        outline: none;
    }

    .line{
        width: 31.93vw;
        height: 0.37vh;
        background: rgba(48, 100, 143, 1);
    }

    .forget{
        margin-left: 26.04vw;
        margin-top: 1.11vh;
        display: flex;
        align-items: center;
    }

    .icon-people{
        width: 1.04vw;
        height: 1.85vh;
    }

    .forget-password{
        font-size: 1.04vw;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 2.68vh;
        color: rgba(48, 100, 143, 1);
        margin-left: 0.416vw;
    }

    .login-switch{
        display: flex;
        align-items: center;
        height: 3.148vh;
        position: absolute;
        bottom: 9.26vh;
        left: 62.5vw;
        /* margin-bottom: 97px; */
    }

    .icon-arrow{
        width: 1.77vw;
        height: 3.148vh;
    }

    .link-switch{
        font-size: 1.042vw;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 2.68vh;
        color: rgba(48, 100, 143, 1);
        margin-left: 1.302vw;
    }


</style>
