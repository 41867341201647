<template>
    <div id="settings" class="common-layout">
        <el-container>
            <el-aside class="aside">
                <img class="icon-back" src="../../../assets/img/back.png" @click="back">
                <el-menu
                    default-active="/settings/user"
                    router>
                    <el-menu-item class="menu" :index="`/settings/user`">用户管理</el-menu-item>
                    <el-menu-item class="menu" :index="`/settings/merchant`">商户管理</el-menu-item>
                </el-menu>
                
            </el-aside>
            <el-main>
                <userInfo></userInfo>
                <router-view></router-view>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import UserInfo from '@/components/UserInfo.vue'

export default {
    components: { 
        'userInfo': UserInfo  
    },
    data(){
        return{
            
        }
    },
    methods: {
        back(){
            this.$router.push('/admin/protocol')
        }
    }
}
</script>

<style>

#settings{
    width: 100vw;
    height: 100vh;
    background: rgba(248, 250, 251, 1);
}

.aside{
    padding-top: 13.61vh;
    width: 10.78vw;
}

.menu{
    background: rgba(248, 250, 251, 1);
    font-size: 1.04vw;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 2.68vh;
    justify-content: center;
}

.icon-back{
    position: absolute;
    left: 1.25vw;
    top: 5.37vh;
}

</style>
