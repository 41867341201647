<template>
    <div id="order">
        <div class="order-header">
            <el-upload
                class="upload-demo"
                :headers="headerObj"
                action="https://wechatapp-api.hhsm.cc/sales_invoice/import"
                method="post"
                accept=".xls, .xlsx"
                :on-success="successUpload"
                :on-error="errorUpload"
            >
                <el-button type="primary" class="add-protocol">
                        <img class="add-icon" src="../../assets/img/add.png">
                        <span class="text1">上传订单</span>
                </el-button>
            </el-upload>
            <el-button @click="downloadTemplate" type="primary" class="add-protocol">
                    <img class="add-icon" src="../../assets/img/download.png">
                    <span class="text1">下载模板</span>
            </el-button>
            <div>
                <el-button @click="exportVisible=true" type="primary" class="add-protocol">
                    <img class="add-icon" src="../../assets/img/export.png">
                    <span class="text1">导出订单</span>
                </el-button>
            </div>
            
            <el-date-picker v-model="year" type="year" :clearable="false" @change="changeYear" style="width: 7.5vw;"/>
        </div>

        <el-dialog @close="closeExport" v-model="exportVisible" width="25%"  title="导出订单">
            <div class="export-content">
                <div class="export-label">请选择导出日期范围：</div>
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 18vw;"
                />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeExport">取消</el-button>
                    <el-button type="primary" @click="exportOrder">确认</el-button>
                </span>
            </template>
        </el-dialog> 

        <div class="main">
            <el-table :data="orderList" class="table-head" row-class-name="table-row" max-height="530px">
                <el-table-column prop="number" label="序号" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.ID }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderDate" label="订单日期" align="center" label-class-name="column" width="130px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.BillDate.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderNum" label="订单编号" align="center" label-class-name="column" width="180px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.BillNumber }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center" label-class-name="column" width="130px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CreatedAt.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="customer" label="客户" align="center" label-class-name="column" width="200px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Customer }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="department" label="部门" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Department }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="salesPerson" label="业务员" align="center" label-class-name="column" width="120px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Salesperson }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stock" label="存货" align="center" label-class-name="column" width="220px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Inventory }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="specification" label="规格" align="center" label-class-name="column" width="110px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Specification }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="单位" align="center" label-class-name="column" width="110px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Unit }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="quantity" label="数量" align="center" label-class-name="column" >
                    <template #default="scope">
                        <span class="row">{{ scope.row.Quantity/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="unitPrice" label="单价" align="center" label-class-name="column" >
                    <template #default="scope">
                        <span class="row">{{ scope.row.UnitPrice/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="金额" align="center" label-class-name="column" width="120px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Amount/100 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="gift" label="赠品" align="center" label-class-name="column" >
                    <template #default="scope">
                        <el-button :class="scope.row.Gift? 'button-true': 'button-false'">{{ scope.row.Gift? '是': '否' }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="amountReceived" label="客户编码" align="center" label-class-name="column" width="120px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CustomerCode }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                v-model:current-page="currentPage"                                
                layout="total, prev, pager, next, jumper"
                :total="total"            
                @current-change="switchPages"         
                />
            </div>   
        </div>
    </div>
</template>

<script>
import {get_order_list, export_order, download_template} from "@/network/order"
import { onBeforeMount, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'


export default {
    setup(){
        let currentPage = ref(1)
        let orderList = ref([])
        let total = ref(0);
        let pageSize = ref(10)
        let totalAmount = ref(1000)
        const headerObj = reactive({ 'Authorization': Cookies.get('token') })
        let queryDate = ref('')
        let queryInfo = reactive({
            query: ''  
        })
        let year = ref('2023')
        let exportVisible = ref(false)
        let dateRange = ref()
        
        

        onBeforeMount(() =>{
            getOrderList()
        })

        const getOrderList = () =>{
            get_order_list(currentPage.value, pageSize.value, Number(year.value))
            .then((res =>{
                total.value = res.data.data.count
                orderList.value = res.data.data.salesInvoice
            }))
            .catch((err) =>{
                console.log(err)
            })
        }

        const switchPages = (e) =>{
            currentPage.value = e
            getOrderList()
        }

        const successUpload = () =>{
            ElMessage({
                message: '订单上传成功',
                type: 'success',
            })
            getOrderList()
        }

        const errorUpload = (err) =>{
            let msg =JSON.parse(err.message).message.replace('invalid request: ', '')
            ElMessage({
                showClose: true,
                message: msg,
                type: 'error',
                duration: 0
            })
        }

        // 导出订单
        const exportOrder = () =>{
            let startDate = dateRange.value[0]
            let endDate = dateRange.value[1]
            export_order(startDate, endDate)
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '订单列表.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '订单列表导出成功',
                    type: 'success',
                })
                closeExport()
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 关闭导出弹窗
        const closeExport = () =>{
            dateRange.value = ''
            exportVisible.value = false
        }

        // 下载订单模板
        const downloadTemplate = () =>{
            download_template()
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '订单模板.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '订单模板下载成功',
                    type: 'success',
                })
            })
            .catch(err =>{
                console.log(err)
            })
        }

        const changeYear = () =>{
            year.value = year.value.getFullYear().toString()
            orderList.value = []
            getOrderList()
        }

        return {
            currentPage,orderList,total,pageSize,totalAmount,
            headerObj,queryDate,queryInfo,year,exportVisible,dateRange,
            getOrderList,switchPages,successUpload,errorUpload,changeYear,
            exportOrder,closeExport,downloadTemplate
        }

    }
}
</script>

<style>
.order-header{
    display: flex;
    margin-top: 2.407vh;
}

.export-content{
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.export-label{
    font-size: 1.042vw;
    font-weight: 400;
    text-align: left;
    margin-bottom: 2vh;
    margin-left: 1.6vw;
}

.table-row{
    height: 50px;
}

.total-amonut{
    position: absolute;
    left: 2.8125vw;
    bottom: 9.6vh;
    font-size: 1.042vw;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 2.68vh;
}

.pagination{
    position: absolute;
    bottom: 2.87vh;
}

.button-true{
    border-radius: 1.56vw;
    background: #E4FAEE;
    border: none;
    width: 3.125vw;
    height: 3.426vh;
    font-size: 1.042vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.68vh;
    color: #429D72;
}

.button-false{
    border-radius: 1.56vw;
    background: #f6d6d6;
    border: none;
    width: 3.125vw;
    height: 3.426vh;
    font-size: 1.042vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.68vh;
    color: #D43030;
}
</style>