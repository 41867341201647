import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 获取商户列表
export function get_customer_list(page, pageSize) {

    return request({
        url: '/users',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize
        },
    })
}

// 根据部门获取商户
export function get_customer_by_department(agreementTitle, year, department, page, pageSize) {

    return request({
        url: '/user/outerUsersByAgreementDepartment',
        headers: {
            'Authorization': token,
        },
        method: 'get',
        params: {
            agreementTitle,
            year,
            department,
            page,
            pageSize
        }
    })
}

// 根据部门获取商户 不分页获取
export function get_customer_department(agreementTitle, year) {

    return request({
        url: '/user/allOuterUsersByAgreement',
        headers: {
            'Authorization': token,
        },
        method: 'get',
        params: {
            agreementTitle,
            year
        }
    })
}

// 获取已签署和未签署协议商户列表
export function get_customer_sign(title, year) {

    return request({
        url: '/userAgreement/listByTitle',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            title,
            year
        }
    })
}

// 导出已签署和未签署协议商户列表excel
export function export_customer_sign(title, year) {

    return request({
        url: '/userAgreement/listByTitleForExport',
        headers: {
            'Authorization': token
        },
        responseType: "blob",
        method: 'get',
        params: {
            title,
            year
        }
    })
}

// 导出所有商户excel
export function export_customers() {

    return request({
        url: '/user/exportAllUsers',
        headers: {
            'Authorization': token
        },
        responseType: "blob",
        method: 'get'
        
    })
}

// 修改商户信息
export function update_customer(customer) {

    return request({
        url: '/user/updateUserInfo',
        headers: {
            'Authorization': token
        },
        method: 'put',
        data: customer
    })
}

// 批量修改业务员
export function update_salesPerson(oldSalesPerson, newSalesPerson) {

    return request({
        url: '/user/updateSalesPerson',
        headers: {
            'Authorization': token
        },
        method: 'put',
        params: {
            oldSalesPerson,
            newSalesPerson
        }
    })
}

// 获取所有业务员
export function get_salesPersons() {

    return request({
        url: '/user/distinctSalesPerson',
        headers: {
            'Authorization': token
        },
        method: 'get'
        
    })
}

// 下载客户模板
export function download_template() {

    return request({
        url: '/user/excelModel',
        headers: {
            'Authorization': token
        },
        method: 'get',
        responseType: "blob"
    })
}