<template>
    <div id="merchantManagement">
        <div class="order-header">
            <el-upload
                class="upload-demo"
                action="https://wechatapp-api.hhsm.cc/user/import"
                :headers="headerObj"
                method="post"
                accept=".xls, .xlsx"
                :on-success="successUpload"
                :on-error="errorUpload"
            >
                <el-button type="primary" class="add-protocol">
                        <img class="add-icon" src="../../../assets/img/add.png">
                        <span class="text1">导入商户</span>
                    </el-button>
            </el-upload>
            <el-button @click="downloadTemplate" type="primary" class="add-protocol">
                    <img class="add-icon" src="../../../assets/img/download.png">
                    <span class="text1">下载模板</span>
            </el-button>
            <div>
                <el-button @click="exportCustomers" type="primary" class="add-protocol">
                    <img class="add-icon" src="../../../assets/img/export.png">
                    <span class="text1">导出商户</span>
                </el-button>
            </div>
            <el-button @click="updateBatchVisible=true" type="primary" class="add-protocol">
                    <!-- <img class="add-icon" src="../../../assets/img/export.png"> -->
                    <span class="text1">批量修改</span>
            </el-button>
        </div>
        
        <div class="main">
            <el-table :data="merchantList" class="table-head" row-class-name="table-row" max-height="530px">
                <el-table-column label="客户编码" align="center" label-class-name="column" width="100px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CustomerCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="部门" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Department }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="区域" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Area }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="salesperson" label="业务员" align="center" width="100px" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Salesperson }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="客户名称" align="center" label-class-name="column" width="200px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CustomerName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="老板名" align="center" label-class-name="column" width="150px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.BossName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="电话" align="center" label-class-name="column" width="140px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="客户地址" align="center" label-class-name="column" width="200px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Address }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="合作日期" align="center" label-class-name="column" width="130px">
                    <template #default="scope">
                        <span class="row">{{ scope.row.CooperationDate.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="停用" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.isDisabled?'是':'否' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderDate" label="操作" align="center" label-class-name="column">
                    <template #default="scope">
                        <el-popover placement="bottom" popper-class="my-popper" width="100px" trigger="click">
                            <template #reference>
                                <img src="../../../assets/img/operate.png">
                            </template>
                                <div class="edit-draft" @click="openUpdateDialog(scope.$index)">
                                    <el-link type="primary">修改</el-link>
                                </div>
                                <!-- <div class="delete-draft" @click="deleteUnsigned(scope.row.Title)">
                                    <el-link type="primary">删除</el-link>
                                </div>      -->
                        </el-popover>

                    </template>
                    
                </el-table-column>              
            </el-table>
            <div class="pagination">
                <el-pagination
                                                
                layout="total, prev, pager, next, jumper"
                :total="total"            
                @current-change="switchPages"         
                />
            </div>

            <el-dialog @close="closeUpdateDialog" v-model="updateDialogVisible" width="330px">
                <template #header>
                    <div class="dialog-title">修改</div>
                </template>
                <el-form
                    label-position="left"
                    label-width="80px"
                    :model="updateForm"
                    style="max-width: 460px"
                >
                    <el-form-item label="部门">
                        <el-input v-model="updateForm.department" />
                    </el-form-item>
                    <el-form-item label="区域">
                        <el-input v-model="updateForm.area" />
                    </el-form-item>
                    <el-form-item label="业务员">
                        <el-input v-model="updateForm.salesperson" />
                    </el-form-item>
                    <el-form-item label="客户名称">
                        <el-input v-model="updateForm.customerName" />
                    </el-form-item>
                    <el-form-item label="老板名">
                        <el-input v-model="updateForm.bossName" />
                    </el-form-item>
                    <el-form-item label="电话">
                        <el-input v-model="updateForm.phone" />
                    </el-form-item>
                    <el-form-item label="客户地址">
                        <el-input v-model="updateForm.address" />
                    </el-form-item>
                    <el-form-item label="合作日期">
                        <el-date-picker
                            v-model="updateForm.cooperationDate"
                            type="date"                            
                        />
                    </el-form-item>
                    <el-form-item label="是否停用">
                        <el-select v-model="updateForm.isDisabled">
                            <el-option
                            v-for="item in disableOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                    
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button class="button-cancel" @click="addDialogVisible = false">取消</el-button>
                        <el-button class="button-confirm" type="primary" @click="updateCustomer">确认</el-button>
                    </span>
                </template>  
            </el-dialog>

            <el-dialog @close="closeUpdateBatch" v-model="updateBatchVisible" width="330px">
                <template #header>
                    <div class="dialog-title">批量修改业务员</div>
                </template>
                <el-form
                    label-position="left"
                    label-width="80px"
                    :model="salesmanForm"
                    style="max-width: 460px"
                >
                    <el-form-item label="原业务员">
                        <el-select v-model="salesmanForm.oldSalesman" placeholder="请选择">
                            <el-option
                            v-for="item in salesPersonList"
                            :key="item"
                            :label="item"
                            :value="item"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="新业务员">
                        <el-input v-model="salesmanForm.newSalesman" />
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button class="button-cancel" @click="closeUpdateBatch">取消</el-button>
                        <el-button class="button-confirm" type="primary" @click="updateSalesperson">确认</el-button>
                    </span>
                </template>  
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {get_customer_list, export_customers, update_customer, update_salesPerson, get_salesPersons, download_template} from "@/network/customer"
import { onBeforeMount, ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'

export default {

    setup(){
        let currentPage = ref(1)
        let merchantList = ref([])  // 商户列表
        let total = ref(0);
        let pageSize = ref(10)
        let salesPersonList = ref([])  // 业务员列表
        let updateDialogVisible = ref(false)  // 商户修改对话框
        let updateBatchVisible = ref(false)  // 批量修改业务员对话框
        let filters = ref([  // 筛选业务员
            {text: '小黎', value: '小黎'},
            {text: '小余', value: '小余'}
        ])
        const updateForm = reactive({
            'id': 0,
            'customerCode': '',
            'password': '',
            'createdAt': '',
            'updatedAt': '',
            'department': '',
            'area': '',
            'salesperson': '',
            'customerName': '',
            'bossName': '',
            'phone': '',
            'address': '',
            'cooperationDate': '',
            'isDisabled': false
        })
        let disableOptions = ref([
            {
                value: true,
                label: '是',
            },
            {
                value: false,
                label: '否',
            }
        ])
        const salesmanForm = reactive({
            'oldSalesman': '',
            'newSalesman': ''
        })
        const headerObj = reactive({ 'Authorization': Cookies.get('token') })

        onBeforeMount(() =>{
            getCustomerList()
            getAllSalesperson()
        })

        const getCustomerList = () =>{
            get_customer_list(currentPage.value, pageSize.value)
            .then((res =>{
                total.value = res.data.data.userCount
                merchantList.value = res.data.data.users
            }))
            .catch((err) =>{
                console.log(err)
            })
        }

        const switchPages = (e) =>{
            currentPage.value = e
            getCustomerList()
        }
        const successUpload = () =>{
            ElMessage({
                message: '商户列表上传成功',
                type: 'success',
            })
            getCustomerList()
        }

        const errorUpload = () =>{
            ElMessage.error('商户列表上传失败')
        }

        // 导出商户列表
        const exportCustomers = () =>{
            export_customers()
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '商户列表.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '商户列表导出成功',
                    type: 'success',
                })
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 打开修改商户信息弹窗
        const openUpdateDialog = (index) =>{  
            let customer = merchantList.value[index]
            updateForm.id = customer.ID
            updateForm.customerCode = customer.CustomerCode
            updateForm.createdAt = customer.CreatedAt
            updateForm.updatedAt = customer.UpdatedAt
            updateForm.department = customer.Department
            updateForm.area = customer.Area
            updateForm.salesperson = customer.Salesperson
            updateForm.customerName = customer.CustomerName
            updateForm.bossName = customer.BossName
            updateForm.phone = customer.Phone
            updateForm.address = customer.Address
            updateForm.cooperationDate = customer.CooperationDate
            updateForm.isDisabled = customer.IsDisabled
            updateDialogVisible.value=true
        }

        // 修改商户信息
        const updateCustomer = () =>{
            update_customer(updateForm)
            .then(res =>{
                ElMessage({
                    message: '客户信息修改成功',
                    type: 'success',
                })
                getCustomerList()
                updateDialogVisible.value = false
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 关闭修改弹窗清空内容
        const closeUpdateDialog = () =>{
            updateDialogVisible.value = false
        }

        // 批量修改业务员
        const updateSalesperson = () =>{
            update_salesPerson(salesmanForm.oldSalesman, salesmanForm.newSalesman)
            .then(res =>{
                ElMessage({
                    message: '业务员批量修改成功',
                    type: 'success',
                })
                getCustomerList()
                updateBatchVisible.value = false
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 关闭批量修改业务员弹窗并清空内容
        const closeUpdateBatch = () =>{
            salesmanForm.oldSalesman = ''
            salesmanForm.newSalesman = ''
            updateBatchVisible.value = false
        }

        // 获取所有业务员
        const getAllSalesperson = () =>{
            get_salesPersons()
            .then(res =>{
                salesPersonList.value = res.data.data.salesPersons
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 筛选业务员
        const filterSalesperson = ()=>{

        }

        // 下载订单模板
        const downloadTemplate = () =>{
            download_template()
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '商户模板.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '商户模板下载成功',
                    type: 'success',
                })
            })
            .catch(err =>{
                console.log(err)
            })
        }

        return {
            currentPage,merchantList,total,pageSize,headerObj,updateDialogVisible,updateForm,
            disableOptions,updateBatchVisible,salesmanForm,salesPersonList,filters,
            getCustomerList,switchPages,updateCustomer,closeUpdateDialog,getAllSalesperson,
            successUpload,errorUpload,exportCustomers,openUpdateDialog,updateSalesperson,closeUpdateBatch,
            filterSalesperson,downloadTemplate
        }

    }
}
</script>

<style>
.add-merchant{
    width: 14.11vw;
    height: 3.7vh;
    border-radius: 8px;
    background: #2A82E4;
    margin-left: 1.3vw;
}
</style>