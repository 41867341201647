<template>
    <div id="loginPhone" class="box1">
        <div class="box2">
            <div class="left">
                <img class="img1" src="https://img.js.design/assets/img/648be2fb8a865ac9711dde06.png#be1e0d4237986e50fbec66ae23288c2d">
                <img class="img2" src="https://img.js.design/assets/img/648be2f59c77113c861286ba.png#c7bd041233684a0c7386f41e87d06bc9">
                <div class="company">阜阳市华海商贸有限公司</div>
            </div>
            <div class="right">
                <form>
                    <div class="phone-login">手机号登录</div>
                    <div class="input-box">
                        <input class="input1" v-model="phone" maxlength="11" placeholder="请输入手机号" @blur="check_phone" />
                        <div class="line"></div>
                        <div>
                            <input class="input2" v-model="checkcode" maxlength="6" placeholder="请输入验证码" />
                            <el-button @click="sendCheckcode" v-if="countNum==60 || countNum==-1" class="get-checkcode">获取验证码</el-button>
                            <el-button v-else class="get-checkcode">{{ countNum }}s后重新发送</el-button>
                        </div>
                        
                        <div class="line"></div>
                    </div>
                    <!-- <div class="forget">
                        <img class="icon-people" src="../../assets/img/people.png">
                        <el-link type="primary" href="" class="forget-password">忘记密码</el-link>
                    </div> -->
                    
                    <el-button @click="login" type="primary" class="button">
                        <div class="button-text">立即登录</div>
                    </el-button>
                </form>

                <div class="login-switch">
                    <img class="icon-arrow" src="../../assets/img/arrow-right.png">
                    <el-link type="primary" href="#/login_password" class="link-switch">密码登录</el-link>
                </div>
                
            </div>
        </div>
        
    </div>
    
</template>

<script>
import { ElMessage } from 'element-plus'
import { request_verify_code, login_with_verify_code } from '@/network/login'
import Cookies from 'js-cookie'

export default {


    data(){
        return {
            phone: '',
            checkcode: '',
            countNum: 60  // 倒计时初始值
        }
    },
    methods:{
        
        check_phone(){
            let phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            if(!phoneReg.test(this.phone)){
                alert('手机号格式错误')
            }

        },

        sendCheckcode(){
            let phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            if(!phoneReg.test(this.phone)){
                alert('手机号格式错误')
            }else{
                request_verify_code(this.phone)
                .then((res) =>{
                    ElMessage({
                        message: '验证码已发送',
                        type: 'success',
                    })
                    this.countdown()
                    })
                .catch((err) =>{
                    console.log(err)
                })

            }

        },

        // 验证码倒计时
        countdown(){
            let count = this.countNum
            let timer = setInterval(() =>{
                count -= 1
                this.countNum = count
                if(count<0){
                    clearInterval(timer)
                    this.countNum = 60
                }
            }, 1000)
        },

        // 验证码登录
        login(){
            if(this.phone == ''){
                ElMessage({
                    message: '手机号不能为空',
                    type: 'warning',
                })
            }
            else if(this.checkcode == ''){
                ElMessage({
                    message: '验证码不能为空',
                    type: 'warning',
                })
            }else{
                login_with_verify_code(this.phone, this.checkcode)
                .then((res) =>{
                    let token = res.data.data.accessToken
                    Cookies.set('token', token)
                    this.$router.push('/admin/protocol')
                })
                .catch((err) =>{
                    console.log(err)
                    ElMessage.error('验证码错误')
                })
            }

        }
    }
}
</script>

<style>
.get-checkcode{
    width: 7.29vw;
    height: 5.6vh;
    position: absolute;
    top: 42vh;
    right: 8.54vw;
    border-radius: 4px;
    border: 2px solid #2A82E4;
    font-size: 1vw;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 3.148vh;
    color: #2A82E4;
}
</style>
