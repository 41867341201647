import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 获取协议列表
export function get_protocol_list(page, pageSize, year) {

    return request({
        url: '/agreement/list',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize,
            year: year
        }
    })
}

// 获取客户协议列表
export function get_useragreement_list(page, pageSize, year) {

    return request({
        url: '/userAgreement/list',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize,
            year: year
        }
    })
}

// 获取客户协议列表
export function get_agreement_list(page, pageSize, year, offset) {

    return request({
        url: '/agreement/list/sign',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            page: page,
            pageSize: pageSize,
            year: year,
            offset: offset
        }
    })
}

// 创建协议
export function create_protocol(threshold, attribute, percentage, title, year) {

    return request({
        url: '/agreement/create',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: {
            amount_threshold: threshold,
            goods_attribute: attribute,
            rebate_percentage_10000: percentage,
            title: title,
            year: year
        }
    })
}

// 创建协议列表
export function create_protocol_list(protocolList) {

    return request({
        url: '/agreement/create_in_batch',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: protocolList
    })
}

// 发放协议
export function distribute_protocol(agreementTitle, customerCode, year) {

    return request({
        url: '/userAgreement/create',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: {
            agreementTitle: agreementTitle,
            customerCode: customerCode,
            year: year
        }
    })
}

// 批量发放协议
export function distribute_protocol_list(customerList) {

    return request({
        url: '/userAgreement/createInBatch',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: customerList
    })
}

// 撤销未签署的客户协议
export function delete_unsigned_protocol(title, year) {

    return request({
        url: '/userAgreement/deleteUnsignedByTitleAndYear',
        headers: {
            'Authorization': token
        },
        method: 'delete',
        params: {
            title,
            year
        }
    })
}


// 上传协议pdf
export function upload_protocol(AgreementTitle, Year, file) {

    return request({
        url: '/file/upload',
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        },
        method: 'post',
        data: {   
            file: file
        },
        params: {
            AgreementTitle: AgreementTitle,
            Year: Year,
        }
    })
}

// 下载协议pdf
export function download_protocol(AgreementTitle, Year) {

    return request({
        url: '/file/download',
        headers: {
            'Authorization': token,
        },
        method: 'get',
        responseType: "blob",
        params: {
            AgreementTitle: AgreementTitle,
            Year: Year
        }
    })
}

// 根据名称获取协议详情
export function get_agreement_detail(title) {

    return request({
        url: '/agreement/title',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            title
        }
    })
}

// 检查协议名称是否重复
export function check_agreement_title(title) {

    return request({
        url: '/agreement/check/title',
        headers: {
            'Authorization': token
        },
        method: 'get',
        params: {
            title
        }
    })
}