import request from '@/utils/request'
import Cookies from 'js-cookie'

let token = Cookies.get('token')

// 获取管理员登录信息
export function get_profile() {
    
    return request({
        url: '/manager/profile',
        headers: {
            'Authorization': token
        },
        method: 'get'
    })
}

// 获取管理员登录状态
export function get_status() {
    
    return request({
        url: '/manager/login/status',
        headers: {

        },
        method: 'get'
    })
}

// 修改密码时发送短信验证码
export function send_sms_code(phone) {

    return request({
        url: '/manager/sendSmsCode/ResetPwd',
        headers: {
            'Authorization': token
        },
        method: 'post',
        params: {
            phone: phone
        }
    })
}

// 根据短信验证码修改密码
export function update_password(code, password, phone) {

    return request({
        url: '/manager/updatePwdWithSmsCode',
        headers: {
            'Authorization': token
        },
        method: 'post',
        data: {
            code: code,
            password: password,
            phone: phone
        }
    })
}