<template>
    <div id="protocal">
        <div class="protocol-header">
            <el-button type="primary" class="add-protocol" @click="addProtocolVisible=true">
                <img class="add-icon" src="../../assets/img/add.png">
                <span class="text1">添加协议</span>
            </el-button>
            <el-date-picker v-model="year" type="year" :clearable="false" @change="changeYear" style="width: 7.5vw;"/>
        </div>
        <el-dialog @close="handleClose" v-model="addProtocolVisible" class="dialog-protocol">
            <div class="dialog-display">
                <div class="upload-button">
                    <el-upload v-if="isCheck==false"
                        ref="upload"
                        :headers="headerObj"
                        action=""
                        method="post"
                        accept=".pdf"
                        :file-list="fileList"
                        :http-request="httpRequest"
                        :limit="1"
                        :on-success="successUpload"
                        :on-error="errorUpload"
                        :auto-upload="false"
                        >
                            <el-button type="primary" class="upload-protocol">
                                <img class="add-icon" src="../../assets/img/add.png">
                                <span class="text1">上传协议</span>
                            </el-button>
                            
                    </el-upload>
                    <el-button @click="downloadPDF" v-else type="primary" class="upload-protocol">
                        <span class="text1">查看协议</span>
                    </el-button>
                </div>

                <div class="dialog-head">
                    <div class="protocol-title">协议名称：</div>
                    <el-input v-model="title" class="input-title" placeholder="请输入协议名称" />
                    <div class="protocol-year">入账财年：</div>
                    <el-date-picker v-model="protocolYear" type="year" style="margin-left: 0.5vw; width: 7vw;" />
                </div>

                <div class="protocol-content" v-for="(item, key) in addProtocolList">
                    <el-form>
                        <el-select v-model="item.goods_attribute" class="wine-select" placeholder="选择酒类">
                            <el-option label="高级酒" value="高级酒" />
                            <el-option label="所有酒" value="普通酒" />
                        </el-select>
                        <span class="text-protocol">已支付款达到</span>
                        <el-input type="number" class="input-pay" v-model="item.amount_threshold"/>
                        <span class="text-protocol">万元，发放</span>
                        <el-input type="number" class="input-pay" v-model="item.rebate_percentage_10000">
                        </el-input>
                        <span class="text-protocol">%返利</span>
                        
                    </el-form>
                    <el-icon v-if="isCheck==false && key>0" class="icon-remove" size="20px" @click="removeProtocolDetail(key)"><Delete /></el-icon>
                </div>

                <el-icon v-if="isCheck==false" size="24px" @click="addProtocolDetail"><CirclePlus /></el-icon>
            </div> 
            <template #footer>
                <span v-if="isCheck==false" class="dialog-footer">
                    <el-button v-if="isDraft==false" @click="createProtocolDraft">保存草稿</el-button>
                    <el-button v-if="isDraft==true" @click="updateDraft">保存草稿</el-button>
                    <el-button type="primary" @click="createProtocolList">发布</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog @close="handleInnerClose" v-model="innerVisible" width="30%" title="请选择发放商户">
            <div class="distribute-content">
                <div class="customer-name">商户名称</div>
                <el-cascader
                v-model="customerCode"
                :options="merchantOptions"
                :props="cascaderProps"
                collapse-tags
                collapse-tags-tooltip
                clearable
                :show-all-levels="false"
                />
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="innerVisible = false">取消发放</el-button>
                    <el-button type="primary" @click="distributeProtocol">确认发放</el-button>
                </span>
            </template>
        </el-dialog> 

        <div class="main">
            <el-table :data="totalList" class="table-head" row-class-name="table-row" :cell-style="numberClass">
                <el-table-column label="序号" type="index" align="center"  label-class-name="column" width="100px">
                    <!-- <template #default="scope">
                        <span class="row">{{ scope.row.ID }}</span>
                    </template> -->
                </el-table-column>
                <el-table-column label="协议名称" align="center" label-class-name="column">
                    <template #default="scope">
                        <span v-if="currentPage==1 && scope.$index<draftNum" class="row">{{ scope.row.Title }}</span>
                        <el-link v-else @click="getProtocolByTitle(scope.row.Title)" class="row" type="primary">{{ scope.row.Title }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="签署情况" align="center" label-class-name="column">
                    <template #default="scope">
                        <span v-if="currentPage==1 && scope.$index<draftNum" class="row">/</span>
                        <el-link v-else @click="openSignDialog(scope.row.Title)" class="row" type="primary">{{ scope.row.SignUserNum }}/{{ scope.row.TotalUserNum }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="签署状态" align="center" label-class-name="column">
                    <template #default="scope">
                        <el-button v-if="currentPage==1 && scope.$index<draftNum" :class="buttonState('草稿')">草稿</el-button>
                        <el-button v-else :class="buttonState(scope.row.SignUserNum==scope.row.TotalUserNum)">{{ scope.row.SignUserNum==scope.row.TotalUserNum? '完成': '待完成' }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="是否停用" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.IsDisabled ? '是' : '否' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" label-class-name="column">
                    <template #default="scope">
                        <el-popover placement="bottom" popper-class="my-popper" width="100px" trigger="click">
                            <template #reference>
                                <img src="../../assets/img/operate.png">
                            </template>
                            <div v-if="currentPage==1 && scope.$index<draftNum">
                                <div class="edit-draft" @click="getDraftByTitle(scope.row.Title)">
                                    <el-link type="primary">编辑草稿</el-link>
                                </div>
                                <div class="delete-draft" @click="openDeleteDialog(scope.row.Title)">
                                    <el-link type="primary">删除草稿</el-link>
                                </div>
                            </div>
                            <div v-else>
                                <div class="edit-draft" @click="addDistribute(scope.row.Title)">
                                    <el-link type="primary">新增发放</el-link>
                                </div>
                                <div class="delete-draft" @click="deleteUnsigned(scope.row.Title)">
                                    <el-link type="primary">撤销发放</el-link>
                                </div>
                            </div>  
                        </el-popover>
                        
                    </template>    
                </el-table-column>
            </el-table>
            <el-dialog
                v-model="deleteDraftVisible"
                title=""
                width="20%"
            >
                <div class="delete-dialog">是否删除该草稿协议</div>
                <template #footer>
                <span class="dialog-footer">
                    <el-button @click="deleteDraftVisible = false">取消</el-button>
                    <el-button type="primary" @click="deleteDraft(deleteDraftTitle)">
                    确认
                    </el-button>
                </span>
                </template>
            </el-dialog>
            <div class="pagination">
                <el-pagination
                v-model:current-page="currentPage"                                
                layout="total, prev, pager, next, jumper"
                :total="total"            
                @current-change="switchPages"         
                />
            </div>
        </div>

        <el-dialog @close="handleCustomersClose" v-model="signingVisible">
            <div class="button-sign">
                <el-button type="primary" @click="getSignList">已签署</el-button>
                <el-button @click="getUnsignList">未签署</el-button>
            </div>      
            
            <div class="merchant-display">
                <el-scrollbar max-height="400px">
                    <el-row :gutter="20" justify="space-around">
                        <el-col :span="6" v-for="item in displayCustomers">
                            <div class="grid-content" >
                                <el-text truncated>{{ item.CustomerName }}</el-text>
                            </div>
                        </el-col>
                        
                    </el-row>
                    
                </el-scrollbar>
            </div>
            <el-button @click="exportExcel" class="button-copy" type="primary">导出</el-button>
        </el-dialog>
    </div>
    
</template>

<script>
import {get_agreement_list, create_protocol_list,  distribute_protocol_list, upload_protocol, get_agreement_detail, check_agreement_title, download_protocol, delete_unsigned_protocol} from "@/network/protocol"
import {create_protocol_draft, get_draft_list, get_draft_by_title, delete_protocol_draft, check_draft_title, edit_draft } from "@/network/agreementDraft"
import { onBeforeMount, reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get_customer_sign, get_customer_department, export_customer_sign} from "@/network/customer"
import Cookies from 'js-cookie'
import { getCurrentInstance, onMounted } from '@vue/runtime-core'

export default {

    setup(){
        let currentPage = ref(1)
        let protocolList = ref([])  // 正式协议列表
        let total = ref(0);  // 协议总数
        let pageSize = ref(10)  
        let year = ref('2023')  // 入账财年
        let addProtocolVisible = ref(false)
        let upload = ref()
        let fileList = ref([])
        let title = ref('')  // 新增协议名称
        let protocolYear = ref('2023')  // 新增协议入账财年
        let addProtocolList = ref([
            {
                goods_attribute: '',
                amount_threshold: 0,
                rebate_percentage_10000: 0,
            }
        ])
        let merchantList = ref([])  // 商户列表
        let customerCode = ref('')  // 商户编码
        let innerVisible = ref(false)  // 二次弹窗
        let distributeList = ref([])  // 协议发放列表
        let draftNum = ref(0)  // 草稿数量
        let draftList = ref([])  // 草稿列表
        let totalList = ref([])  // 所有协议列表
        let deleteDraftVisible = ref(false)
        let deleteDraftTitle = ref('')  // 待删除草稿名
        let signingVisible = ref(false)  // 签署情况弹窗
        let signedCustomers = ref([])  // 已签署协议商户列表
        let unSignedCustomers = ref([])  // 未签署协议商户列表
        let displayCustomers = ref([])  // 弹窗展示的商户列表
        let isDraft = ref(false)  // 当前协议窗口是否是草稿
        let isCheck = ref(false)  // 当前协议窗口是否为查看状态
        const cascaderProps = { 
            multiple: true,
            value: 'CustomerCode',
            label: 'CustomerName'
         }
        let merchantOptions = ref([])

        const headerObj = reactive({ 'Authorization': Cookies.get('token') })
        let currentInstance = ''  

        onBeforeMount(() =>{
            getTotalList()           
        })

        onMounted(() => {
            currentInstance = getCurrentInstance()
        })

        // 获取第一页草稿与正式协议列表
        const getTotalList = async () => {
            const draft = await get_draft_list(1, 10, Number(year.value), 0)
            const draftList = draft.data.data.agreementDrafts
            draftNum.value = draftList.length
            const protocal = await get_agreement_list(1, 10-draftNum.value, Number(year.value), 0)
            const protocolNum = protocal.data.data.agreementCount
            const protocolList = protocal.data.data.agreementsWithSign
            total.value = draftNum.value + protocolNum
            if(draftList==null && protocolList!=null){
                totalList.value = protocolList
            }else if(protocolList==null && draftList!=null){
                totalList.value = draftList
            }else if(draftList!=null && protocolList!=null){
                totalList.value = [...draftList, ...protocolList]
            }
        }

        // 获取协议列表
        const getProtocolList = (currentPage, pageSize, offset) =>{
            get_agreement_list(currentPage, pageSize, Number(year.value), offset)
            .then((res =>{
                // console.log(res)
                total.value = res.data.data.agreementCount
                protocolList.value = res.data.data.agreementsWithSign

            }))
            .catch((err) =>{
                console.log(err)
            })
        }

        const switchPages = async (e) =>{
            currentPage.value = e
            if(e==1){
                getTotalList()
            }else{
                const protocal = await get_agreement_list(1, 10, Number(year.value), 10 - draftNum.value)
                const protocolList = protocal.data.data.agreementsWithSign
                totalList.value = protocolList
            }
        }

        // 上传协议时请求
        const httpRequest = (param) => {
            let file = param.file 
            upload_protocol(title.value, protocolYear.value, file)
            .then(res =>{
                // console.log(res)
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 上传协议pdf
        const uploadPdf = () =>{
            // currentInstance.ctx.$refs.upload.submit()
            upload.value.submit()
        }

        // 成功上传协议pdf
        const successUpload = () =>{
            console.log("协议上传成功")
        }

        const errorUpload = (err) =>{
            ElMessage.error('协议上传失败')
            console.log(err)
        }

        // 下载协议PDF
        const downloadPDF = () =>{
            download_protocol(title.value, year.value)
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = title.value + '.pdf';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '文件导出成功',
                    type: 'success',
                })
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 点击加号按钮添加一行协议
        const addProtocolDetail = () => {
            addProtocolList.value.push({
                goods_attribute: '',
                amount_threshold: 0,
                rebate_percentage_10000: 0,
            })
        }

        // 删除某一行协议
        const removeProtocolDetail = (key)=> {
            addProtocolList.value.splice(key, 1)
        }

        // 表单校验
        const formRule = () =>{
            let len = addProtocolList.value.length
            for(let i=0;i<len;i++){
                let rebate = addProtocolList.value[i].rebate_percentage_10000
                if(addProtocolList.value[i].goods_attribute == ''){
                    ElMessage({
                        message: '酒类属性不能为空',
                        type: 'warning',
                    })
                    return false
                }else if(Number(addProtocolList.value[i].amount_threshold)<0){
                    ElMessage({
                        message: '支付款不能为负数',
                        type: 'warning',
                    })
                    return false
                }else if(Number(rebate)< 0 || Number(rebate)>100){
                    ElMessage({
                        message: '返利百分比必须在0~100之间',
                        type: 'warning',
                    })
                    return false
                } 
            }
            return true
        }

        // 新建协议草稿
        const createProtocolDraft = async () => {
            let flag = await check_draft_title(title.value)
            if(title.value == ''){
                ElMessage({
                    message: '协议名称不能为空',
                    type: 'warning',
                })
            }
            else if(flag.data.data){
                ElMessage({
                    message: '草稿协议名称重复',
                    type: 'warning',
                })   
            }
            else if(formRule()==false){
                console.log("请输入正确完整的表单")
            }else{
                let protocolList = getProtocolForm()
                // console.log(protocolList)
                create_protocol_draft(protocolList)
                .then(res =>{
                    getTotalList()
                    addProtocolVisible.value = false
                    ElMessage({
                        message: '保存协议草稿成功',
                        type: 'success',
                    })
                })
                .catch(err =>{
                    console.log(err)
                })
            }
        }

        // 根据名称获取正式协议
        const getProtocolByTitle = (protocolTitle) => {
            isCheck.value = true
            get_agreement_detail(protocolTitle)
            .then(res =>{
                let agreement = res.data.data.agreement
                title.value = agreement[0].Title
                protocolYear.value = agreement[0].Year.toString()
                addProtocolList.value.pop()
                for(let i=0;i<agreement.length;i++){
                    addProtocolList.value.push({})
                    addProtocolList.value[i].goods_attribute = agreement[i].GoodsAttribute
                    if(agreement[i].AmountThreshold==1){
                        addProtocolList.value[i].amount_threshold = 0
                    }else{
                        addProtocolList.value[i].amount_threshold = agreement[i].AmountThreshold/1000000
                    }
                    addProtocolList.value[i].rebate_percentage_10000 = agreement[i].RebatePercentage/100
                }
                addProtocolVisible.value = true                         
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 根据名称获取协议草稿
        const getDraftByTitle = (protocolTitle) => {
            isDraft.value = true
            get_draft_by_title(protocolTitle)
            .then(res =>{
                draftList = res.data.data
                title.value = draftList[0].Title
                protocolYear.value = draftList[0].Year.toString()
                addProtocolList.value.pop()
                for(let i=0;i<draftList.length;i++){
                    addProtocolList.value.push({})
                    addProtocolList.value[i].goods_attribute = draftList[i].GoodsAttribute
                    if(draftList[i].AmountThreshold==1){
                        addProtocolList.value[i].amount_threshold = 0
                    }else{
                        addProtocolList.value[i].amount_threshold = draftList[i].AmountThreshold/1000000
                    }
                    addProtocolList.value[i].rebate_percentage_10000 = draftList[i].RebatePercentage/100
                }
                addProtocolVisible.value = true                          
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 修改草稿
        const updateDraft = () => {
            if(title.value == ''){
                ElMessage({
                    message: '协议名称不能为空',
                    type: 'warning',
                })
            }else if(formRule()==false){
                console.log("请输入正确完整的表单")
            }else{
                let protocolList = getProtocolForm()
                edit_draft(protocolList)
                .then(res =>{
                    getTotalList()
                    addProtocolVisible.value = false
                    ElMessage({
                        message: '保存协议草稿成功',
                        type: 'success',
                    })
                })
                .catch(err =>{
                    console.log(err)
                })
            }
        }

        // 确认是否删除草稿
        const openDeleteDialog = (title) => {
            deleteDraftVisible.value=true
            deleteDraftTitle.value = title
        }

        // 删除协议草稿
        const deleteDraft = (title) => {
            delete_protocol_draft(title)
            .then(res =>{
                getTotalList()
                ElMessage({
                    message: '删除草稿成功',
                    type: 'success',
                })
                deleteDraftVisible.value=false
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 获取协议表单
        const getProtocolForm = () => {
            let len = addProtocolList.value.length
            let addList = []
            let protocalDetail = {
                title: title.value,
                year: Number(protocolYear.value)
            }
            for(let i=0;i<len;i++){
                if(Number(addProtocolList.value[i].amount_threshold)==0){
                    protocalDetail.amount_threshold = 1
                }else{
                    protocalDetail.amount_threshold = Math.round(Number(addProtocolList.value[i].amount_threshold)*10000*100)
                }   
                protocalDetail.rebate_percentage_10000 = Math.round(Number(addProtocolList.value[i].rebate_percentage_10000)*100)
                protocalDetail.goods_attribute = addProtocolList.value[i].goods_attribute
                addList.push(JSON.parse(JSON.stringify(protocalDetail)))
            }
            return addList
        }

        // 新建协议列表
        const createProtocolList = async () => {
            let fileList =  document.querySelector('.el-upload-list__item')
            let flag = await check_agreement_title(title.value)
            if(title.value == ''){
                ElMessage({
                    message: '协议名称不能为空',
                    type: 'warning',
                })
            }else if(flag.data.data.exist){
                ElMessage({
                    message: '协议名称重复',
                    type: 'warning',
                })   
            }else if(formRule()==false){
                console.log("请输入正确完整的表单")
            }else if(fileList == null){
                ElMessage({
                    message: '协议PDF未上传',
                    type: 'warning',
                }) 
            }else{
                let addList = getProtocolForm()
                create_protocol_list(addList)
                .then((res =>{
                    uploadPdf()
                    addProtocolVisible.value = false
                    getTotalList()
                    ElMessage({
                        message: '新建协议成功',
                        type: 'success',
                    })                  
                }))
                .catch((err) =>{
                    addProtocolVisible.value = false
                    ElMessage.error('新建协议失败')
                    console.log(err)
                })
            }

        }

        // 协议签署状态
        const buttonState = (signState) => {
            return {
                'button-todo': signState==false,
                'button-done': signState==true,
                'button-draft': signState=='草稿'
            }
        }

        // 发放协议
        const distributeProtocol = () =>{
            let len = customerCode.value.length
            for(let i=0;i<len;i++){
                for(let j=1;j<customerCode.value[i].length;j++){
                    distributeList.value.push({
                        agreementTitle: title.value,
                        year: Number(protocolYear.value),
                        customerCode: customerCode.value[i][j]
                    })
                }
                
            }        
            distribute_protocol_list(distributeList.value)
            .then((res =>{
                getTotalList()
                currentPage.value = 1
                innerVisible.value = false
                ElMessage({
                    message: '协议发放成功',
                    type: 'success',
                })
            }))
            .catch((err) =>{
                console.log(err)
            })
        }

        // 新增发放
        const addDistribute = (protocolTitle) =>{       
            title.value = protocolTitle
            get_customer_department(protocolTitle, year.value)
            .then(res =>{
                let customers = res.data.data.users
                let keys = Object.keys(customers)
                for(let i=0;i<keys.length;i++){
                    merchantOptions.value.push({})
                    merchantOptions.value[i]['CustomerName'] = keys[i]
                    merchantOptions.value[i]['children'] = customers[keys[i]]
                }
            })
            .catch(err =>{
                console.log(err)
            })

            innerVisible.value = true
        }

        // 打开签署协议商户弹窗
        const openSignDialog = (protocolTitle) =>{
            getCustomerSign(protocolTitle)
            signingVisible.value = true
            title.value = protocolTitle
        }

        const getSignList = () =>{
            displayCustomers.value = signedCustomers.value
        }

        const getUnsignList = () =>{
            displayCustomers.value = unSignedCustomers.value
        }

        // 获取已签署和未签署协议商户列表
        const getCustomerSign = (agreementTitle) =>{
            get_customer_sign(agreementTitle, Number(year.value))
            .then(res =>{
                signedCustomers.value = res.data.data.SignedUsers
                unSignedCustomers.value = res.data.data.UnsignedUsers
                displayCustomers.value = res.data.data.SignedUsers
            })
            .catch(err =>{
                console.log(err)
            })
        }

        // 关闭协议弹窗时内容清空
        const handleClose = () =>{
            isCheck.value = false
            isDraft.value = false
            title.value = ''
            protocolYear.value = '2023'
            addProtocolList.value = [
                {
                    goods_attribute: '',
                    amount_threshold: 0,
                    rebate_percentage_10000: 0,
                }
            ]
        }

        // 关闭发放协议弹窗时内容清空
        const handleInnerClose = () =>{
            customerCode.value = []
            merchantOptions.value = []
            title.value = ''
            getTotalList()
        }

        // 关闭签署商户列表弹窗时内容清空
        const handleCustomersClose = () =>{          
            title.value = ''
        }

        // 撤销未签署的客户协议
        const deleteUnsigned = (title) =>{
            ElMessageBox.confirm(
                '您将撤销未签署的客户协议，是否继续?',
                '提示',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            )
            .then(() => {
                delete_unsigned_protocol(title, year.value)
                .then(res =>{
                    ElMessage({
                        message: '未签署的客户协议撤销成功',
                        type: 'success',
                    })
                    getTotalList()
                })
                .catch(err =>{
                    console.log(err)
                })
            })
            .catch(() => {
                
            })
            
        }

        // 导出已签署与未签署的商户excel
        const exportExcel = () => {
            export_customer_sign(title.value ,year.value)
            .then(res =>{
                let blob = res.data;
                const fileReader = new FileReader(); 
                fileReader.readAsDataURL(blob); 
                fileReader.onload = (event) => { 
                    let a = document.createElement('a');
                    let fileName = '协议签署情况表.xlsx';
                    a.download = fileName;
                    a.href = event.target.result; 
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                ElMessage({
                    message: '表格导出成功',
                    type: 'success',
                })
            })
            .catch(err =>{
                console.log(err)
            })
        }



        const numberClass = ({ row, column, rowIndex, columnIndex }) =>{
            if(columnIndex == 0){
                return {
                    color: '#000000',
                    fontSize: '1.042vw'
                }
            }
        }

        const changeYear = () =>{
            year.value = year.value.getFullYear().toString()
            totalList.value = []
            getTotalList()  
        }

        return {
            currentPage,protocolList,total,pageSize,year,
            addProtocolVisible,addProtocolList,
            title,merchantList,innerVisible,customerCode,
            distributeList,protocolYear,headerObj,currentInstance,
            merchantOptions,cascaderProps,fileList,upload,
            draftNum,draftList,totalList,deleteDraftVisible,deleteDraftTitle,signingVisible,
            signedCustomers,unSignedCustomers,displayCustomers,isDraft,isCheck,
            getProtocolList,switchPages,addProtocolDetail,createProtocolList,
            buttonState,distributeProtocol,addDistribute,
            successUpload,errorUpload,uploadPdf,httpRequest,formRule,numberClass,changeYear,
            removeProtocolDetail,createProtocolDraft,getProtocolForm,
            getDraftByTitle,deleteDraft,getTotalList,openDeleteDialog,getCustomerSign,
            openSignDialog,getSignList,getUnsignList,handleClose,getProtocolByTitle,updateDraft,
            handleInnerClose,exportExcel,handleCustomersClose,downloadPDF,deleteUnsigned
        }

    },
    
}
</script>

<style>
#protocol{
    position: relative;
}

.protocol-header{
    display: flex;
    margin-top: 2.407vh;
}

.add-protocol{
    width: 9.6875vw;
    height: 4.2vh;
    border-radius: 8px;
    background: #2A82E4;
    margin-right: 2.5vw;
}

.add-icon{
    width: 1.042vw;
}

.text1{
    font-size: 1.042vw;
    font-weight: 700;
    letter-spacing: 0px;
    margin-left: 0.208vw;
}

.button-chart{
    width: 9.79vw;
    height: 3.7vh;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
}

.chart-icon{
    width: 1.042vw;
}

.text2{
    font-size: 1.042vw;
    font-weight: 400;
    letter-spacing: 0px;
    margin-left: 0.25vw;
    color: #000000;
}

.dialog-protocol{
    width: 40vw;
    border-radius: 8px;
    margin-top: 30vh;
}

.dialog-display{
    display: flex;
    flex-direction: column;
}

.dialog-head{
    display: flex;
    align-items: center;
    margin-bottom: 1.85vh;
    margin-top: 1.85vh;
}

.input-title{
    width: 9vw;
}

.protocol-title{
    font-size: 1vw;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}

.protocol-year{
    font-size: 1vw;
    font-weight: 400;
    margin-left: 2vw;
    color: rgba(0, 0, 0, 1);
}

.upload-button{
    display: flex;
    justify-content: left;
}

.upload-protocol{
    width: 9.6875vw;
    height: 3.7vh;
    border-radius: 8px;
    background: #2A82E4;
}

.protocol-content{
    display: flex;
    align-items: center;
    margin-bottom: 1.85vh;
}

.wine-select{
    width: 7.4vw;
    margin-right: 0.5vw;
}

.text-protocol{
    font-size: 0.9375vw;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 1);
}

.icon-remove{
    margin-left: 0.5vw;
}

.input-pay{
    width: 5.625vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}


.distribute-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.select-row{
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
}

.customer-name{
    font-size: 0.9375vw;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    margin-right: 2vw;
}

.dialog-chart{
    width: 27.08vw;
    height: 35.28vh;
    border-radius: 8px;
    margin-top: 30vh;
    /* position: absolute;
    top: 33.52vh; */
}

.main{
    position: absolute;
    top: 10.648vh;
    width: 86.51vw;
    height: 86.574vh;
    background-color: #FFFFFF;
    border-radius: 30px;
    display: flex;
    justify-content: center;
}

.table-head{  
    margin-top: 1.85vh;  
}

.table-row{
    height: 4.63vh;
}

.column{
    color: #000000;
    font-size: 1.042vw;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 2.68vh;
}

.row{
    color: #000000;
    font-size: 1.042vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.68vh;
}

.button-state{
    border-radius: 30px;
}

.button-todo{
    background-color: #F7F5E5;
    border-radius: 1.56vw;
    width: 5.208vw;
    height: 3.426vh;
    font-size: 1.042vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.68vh;
    color: #D3BF53;
    border: none;
}

.button-done{
    width: 4.167vw;
    height: 3.426vh;
    border-radius: 1.56vw;
    background: #E4FAEE;
    font-size: 1.042vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.68vh;
    color: #429D72;
    border: none;
}

.button-draft{
    width: 4.167vw;
    height: 3.426vh;
    border-radius: 1.56vw;
    background: #E5E5E5;
    font-size: 1.042vw;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.68vh;
    color: #A6A6A6;
    border: none;
}

.pagination{
    position: absolute;
    bottom: 2.87vh;
}

.delete-dialog{
    font-size: 1.042vw;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
}

.edit-draft{
    text-align: center;
}

.delete-draft{
    text-align: center;
    margin-top: 10px;
}

/* 待修改 */
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content{
    border: 1px solid #A6A6A6;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    margin-bottom: 10px;    
}

.merchant-display{
    border: 2px solid #E5E5E5;
    padding: 20px 10px 10px;
}

.button-copy{
    margin-top: 20px;
    margin-bottom: 20px
}

.button-sign{
    margin-bottom: 20px
}

</style>