<template>
    <div id="userManagement">
        <div class="order-header">
            <div @click="addDialogVisible=true">
                <el-button type="primary" class="add-protocol">
                    <img class="add-icon" src="../../../assets/img/add.png">
                    <span class="text1">新建用户</span>
                </el-button>
            </div>
            
        </div>
        <el-dialog v-model="addDialogVisible" width="313px">
            <template #header>
                <div class="dialog-title">新增</div>
            </template>
            <div>
                <div class="dialog-body">
                    <div class="info-row">
                        <span class="info-label">工号</span>
                        <el-input class="add-input" v-model="administrator.employeeID" placeholder="请输入工号" />
                    </div>
                    <div class="info-row">
                        <span class="info-label">姓名</span>
                        <el-input class="add-input" v-model="administrator.username" placeholder="请输入姓名" />
                    </div>
                    <div class="info-row">
                        <span class="info-label">电话</span>
                        <el-input class="add-input" v-model="administrator.phone" placeholder="请输入电话" />
                    </div>
                    <div class="info-row">
                        <span class="info-label">密码</span>
                        <el-input class="add-input" type="password" v-model="administrator.password" placeholder="请输入密码" />
                    </div>
                    <div class="info-row">
                        <span class="info-label">角色</span>
                        <el-select class="add-select" v-model="role" clearable placeholder="请选择">
                            <el-option
                            v-for="item in roleOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                        </el-select>
                        <img>
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="button-cancel" @click="addDialogVisible = false">取消</el-button>
                    <el-button class="button-confirm" type="primary" @click="addAdmin">确认</el-button>
                </span>
            </template>  
        </el-dialog>

        <div class="main">
            <el-table :data="administratorList" class="table-head" row-class-name="table-row">
                <el-table-column prop="orderDate" label="工号" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.EmployeeID }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderDate" label="姓名" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Username }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="roles" label="角色" align="center" label-class-name="column">
                    <template #default="scope">
                        <span class="row">{{ scope.row.Roles==1 ? '普通管理员': scope.row.Roles==2 ? '超级管理员' : 'Boss' }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="orderDate" label="操作" align="center" label-class-name="column">
                    <template #default="scope">
                        <el-popover placement="bottom" popper-class="my-popper" width="100px" trigger="click">
                            <template #reference>
                                <img src="../../../assets/img/operate.png">
                            </template>
                                <div class="text-operate1" @click="updateDialogVisible=true">修改</div>
                                <el-dialog v-model="updateDialogVisible" width="313px">
                                    <template #header>
                                        <div class="dialog-title">修改</div>
                                    </template>
                                    <div>
                                        <div class="dialog-body">
                                            <div class="info-row">
                                                <span class="info-label">姓名</span>
                                                <span class="info-value">{{ scope.row.Username }}</span>
                                                <img class="info-update" src="../../../assets/img/update.png">
                                            </div>
                                            <div class="info-row">
                                                <span class="info-label">电话</span>
                                                <span class="info-value">{{ scope.row.Phone }}</span>
                                                <img class="info-update" src="../../../assets/img/update.png">
                                            </div>
                                            <div class="info-row">
                                                <span class="info-label">角色</span>
                                                <el-select class="update-select" v-model="value" clearable placeholder="请选择">
                                                    <el-option
                                                    v-for="item in roleOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                                    />
                                                </el-select>
                                                <img>
                                            </div>
                                        </div>
                                    </div>
                                    <template #footer>
                                        <span class="dialog-footer">
                                            <el-button class="button-cancel" @click="updateDialogVisible = false">取消</el-button>
                                            <el-button class="button-confirm" type="primary" @click="updateDialogVisible = false">确认</el-button>
                                        </span>
                                    </template>  
                                </el-dialog>
                                <div class="text-operate2" @click="checkRemove(scope.row.EmployeeID)">删除</div>
                            
                        </el-popover>
                    </template>
                    
                </el-table-column>
                
            </el-table>
            <div class="pagination">
                <el-pagination
                v-model:current-page="currentPage"                                
                layout="total, prev, pager, next, jumper"
                :total="administratorList.length"            
                @current-change="switchPages"         
                />
            </div>
        </div>
    </div>
</template>

<script>
import {get_administrator_list, remove_administrator, add_administrator, add_super_administrator} from "@/network/administrator"
import { onBeforeMount, ref, reactive, toRaw } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'

export default{
    setup(){
        let currentPage = ref(1)
        let administratorList = ref([])
        let total = ref(0);
        let pageSize = ref(10)
        let addDialogVisible = ref(false)
        let updateDialogVisible = ref(false)

        const administrator = reactive({
            employeeID: '',         
            phone: '',
            password: '',
            username: '',
        })

        let role = ref(1)
        const roleOptions = ref([
            {
                value: 1,
                label: '普通管理员',
            },
            {
                value: 2,
                label: '超级管理员',
            }
        ])

        onBeforeMount(() =>{
            getAdministratorList()
        })

        const getAdministratorList = () =>{
            get_administrator_list(currentPage.value, pageSize.value)
            .then((res =>{
                total.value = res.data.data.count
                administratorList.value = res.data.data.managers
            }))
            .catch((err) =>{
                console.log(err)
            })
        }

        const checkRemove = (id) => {
            ElMessageBox.confirm(
                '该管理员将被移除。是否继续?',
                '二次确认',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            )
            .then(() => {
                removeAdministrator(id)
            })
        }

        const removeAdministrator = (id) =>{
            remove_administrator(id)
            .then((res) =>{
                getAdministratorList()
            })
            .catch((err) =>[
                console.log(err)
            ])
        }

        const addAdmin = () =>{
            if(role.value == 1){
                addAdministrator()
            }else{
                addSuperAdministrator()
            }
        }

        // 添加普通管理员
        const addAdministrator = () =>{
            add_administrator(toRaw(administrator))
            .then((res) =>{
                addDialogVisible.value = false
                ElMessage({
                    message: '添加管理员成功',
                    type: 'success',
                })
                getAdministratorList()
            })
            .catch((err) =>[
                ElMessage.error('添加管理员失败')
            ])
        }

        // 添加超级管理员
        const addSuperAdministrator = () =>{
            add_super_administrator(toRaw(administrator))
            .then((res) =>{
                addDialogVisible.value = false
                ElMessage({
                    message: '添加管理员成功',
                    type: 'success',
                })
                getAdministratorList()
            })
            .catch((err) =>[
                ElMessage.error('添加管理员失败')
            ])
        }

        const switchPages = (e) =>{
            currentPage.value = e
            getAdministratorList()
        }

        return {
            currentPage,administratorList,total,pageSize,administrator,
            addDialogVisible,updateDialogVisible,role,roleOptions,
            getAdministratorList,switchPages,checkRemove,
            removeAdministrator,addAdmin,addAdministrator,addSuperAdministrator
        }

    }
}
</script>

<style >
.my-popper{
    min-width: 100px!important;
}

.text-operate1{
    text-align: center;
    font-weight: 400;
}

.text-operate2{
    text-align: center;
    font-weight: 400;
    margin-top: 10px;
}

.dialog-title{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(0, 0, 0, 1);
}

.dialog-body{
    display: flex;
    flex-direction: column;
}

.info-row{
    display: flex;
    width: 265px;
    align-items: center;
}

.info-label{
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(56, 56, 56, 1);
}

.add-input{
    width: 215px;
    margin-left: 10px;
}

.add-select{
    width: 215px;
    margin-left: 10px;
}

.info-value{
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(128, 128, 128, 1);
    width: 201px;
    text-align: right;
}

.info-update{
    width: 20px;
    height: 20px;
    margin-left: 8px;
}

.update-select{
    width: 120px;
    margin-left: 105px;
}

.button-cancel{
    width: 66px;
    height: 36px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
}

.button-confirm{
    margin-left: 16px;
    width: 66px;
    height: 36px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
}
</style>